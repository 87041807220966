import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import TextPageTpl from "../../components/TextPageTpl/TextPageTpl.jsx";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "./styles";
import {useNavigate} from "react-router";
import DownloadIcon from '@mui/icons-material/Download';
import {Helmet} from "react-helmet-async";

const QrKurortnyy = () => {

    const classes = useStyles();
    const gClasses = useGStyles();

    const nav = useNavigate();

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    const goToPage = (url) => {
        nav(url);
    };

    return (
        <>
            <Helmet>
                <title>Бронирую! посуточно в ЖК «Курортный» | Ремонт под ключ в ЖК «Курортный» | Доверительное управление в ЖК «Курортный»</title>
                <meta name="description" content="ЖК «Курортный» - снять квартиру посуточно, заказать ремонт под ключ или отдать квартиру в доверительное управление." />
                <meta name="keywords" content="ЖК «Курортный» - снять квартиру посуточно, заказать ремонт под ключ или отдать квартиру в доверительное управление." />
            </Helmet>
            <TextPageTpl withoutPadding={true} withMiniFooter={true}>

                <BreadCrumbs stack={[{name: "QR"}, {name: "Бронирую! в Курортном"}]} style={{padding: "15px 15px 0 15px", marginBottom: 20}}/>

                <div className={classes.mainBanner}>
                    <img src="/images/qr-banner-kurortnyy.jpg" alt=""/>
                    <h1>
                        <p style={{margin: "0 0 14px 0px"}}>ЖК </p><p style={{margin: "0 0 0 9px"}}>«Курортный»</p></h1>
                </div>

               <div className={clsx(gClasses.contentWrapper__text, gClasses.mt10)}>
                   <p>Приветствуем вас!</p>
                   <p><span style={{fontWeight: 600}}>«Бронирую!<sup>®</sup>» - это сервис по поиску и бронированию жилья</span> в Сочи.</p><p>Мы рады видеть вас на нашей странице.</p>
                   <p>Если <span style={{fontWeight: 500}}>вы гость</span>, вы можете найти и <strong style={{color: "#00B2FF"}}>забронировать жилье</strong> посуточно в «Курортном».</p>
                   <p>Если <span style={{fontWeight: 500}}>вы собственник жилья</span>, то вас может заинтересовать <strong style={{color: "#7546E6"}}>ремонт «под ключ»</strong> с гарантией качества и по самым выгодным ценам.</p>
                   <p>Если вы хотите сдать вашу квартиру в аренду, готовы предложить вам сервис  <strong style={{color: "#62BC00"}}>доверительного управления</strong> вашим объектом размещения.</p>

                   <div className={clsx(classes.bannerWrapper, gClasses.mt20) } onClick={() => goToPage("/h/844")}>
                       <img src="/images/qr-banner-rent.jpg" alt=""/>
                       <h2 className="bannerWrapperRent">Снять <br/>жильё</h2>
                       <Button
                           className={gClasses.mediumBtn}
                           variant="contained"
                           style={{background: "#00B2FF"}}
                           size="small"
                       >
                           Жильё в «Курортном»
                       </Button>
                   </div>

                   <div className={classes.bannerWrapper} onClick={() => goToPage("/renovation")}>
                       <img src="/images/qr-banner-renovation.jpg" alt=""/>
                       <h2 className="bannerWrapperRenovation">Ремонт под<br/>ключ</h2>
                       <Button
                           className={gClasses.mediumBtn}
                           variant="contained"
                           style={{background: "#7546E6"}}
                           size="small"
                       >
                           Подробнее
                       </Button>
                   </div>

                   <div className={classes.bannerWrapper}>
                       <img src="/images/qr-banner-trust-management.jpg" alt=""/>
                       <h2 className="bannerWrapperTrustManagement">Доверительное <br/>управление</h2>
                       <Button
                           className={gClasses.mediumBtn}
                           variant="contained"
                           style={{background: "#62BC00"}}
                           size="small"
                           startIcon={<DownloadIcon/>}
                           onClick={() => window.open("/files/booklet_ broniruyu.pdf", "_blank")}
                       >
                           Скачать буклет в pdf
                       </Button>
                   </div>

               </div>

            </TextPageTpl>
        </>
    );
};

export default QrKurortnyy;
