import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import TextPageTpl from "../../components/TextPageTpl/TextPageTpl.jsx";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {Helmet} from "react-helmet-async";

const CrmAgent = () => {

    const gClasses = useGStyles();

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    return (
        <>
            <Helmet>
                <title>Бесплатная CRM-система для работы с недвижимостью в Сочи «Агент».</title>
                <meta name="description" content="«Агент» - легкая и удобная мобильная CRM-система для агентов по недвижимости и управляющих недвижимостью от сервиса «Бронирую!»."/>
                <meta name="keywords" content="«Агент» - легкая и удобная мобильная CRM-система для агентов по недвижимости и управляющих недвижимостью от сервиса «Бронирую!»." />
            </Helmet>

            <TextPageTpl>

                <BreadCrumbs stack={[{name: "CRM-система «агент»"}]} style={{padding: 0, marginBottom: 20}}/>

                <h1>«Агент» - бесплатная CRM-система для работы с недвижимостью в Сочи.</h1>

                <p><strong>«Агент» - легкая и удобная мобильная CRM-система</strong> для агентов по недвижимости и управляющих недвижимостью от сервиса «Бронирую!».</p>
                <p>Невероятные возможности по работе с объектами, управлению рекламой, а также обработке клиентов.</p>
                <p>«Агент» - это сервис объединяющий тысячи агентов по недвижимости.</p>
                <p><strong>Используйте CRM «Агент» бесплатно, навсегда!</strong></p>

                <Button
                    className={gClasses.mediumBtn}
                    fullWidth
                    variant="contained"
                    onClick={() => window.open("https://агент.онлайн/auth?modules=rent", "_blank")}
                    color="green"
                >
                    Открыть CRM «Агент»
                </Button>

            </TextPageTpl>
        </>
    );
};

export default CrmAgent;
