import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import TextPageTpl from "../../components/TextPageTpl/TextPageTpl.jsx";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {Helmet} from "react-helmet-async";

const PopularLocations = () => {

    const gClasses = useGStyles();

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    return (
        <>
            <TextPageTpl>

                <BreadCrumbs stack={[{name: "Знаковые места и популярные локации"}]} style={{padding: 0, marginBottom: 20}}/>

                <h1>Знаковые места и популярные локации</h1>

                <p>Информация в данном разделе находится на стадии наполнения, она появится чуть позже.</p>

            </TextPageTpl>
        </>
    );
};

export default PopularLocations;
