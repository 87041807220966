import React, {useContext, useEffect, useState, useRef} from "react";
import moment from 'moment';
import each from 'lodash/each';
import identity from 'lodash/identity';
import {blue, deepOrange, deepPurple} from "@mui/material/colors";
import {AOIcon, AvitoIcon, CianIcon, DomClickIcon, OtherPlatformIcon} from "../assets/svg-icons.js";


export const randomInteger = (min, max) => {
    // случайное целое число от min до (max+1)
    let rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
};

export const randomStr = (length) => {
    return (Math.random() + 1).toString(36).substring(length)
}

export const rejectTimeout = (ms) => {
    return new Promise( (resolve, reject) => setTimeout(() => reject(new Error('timeout')), ms));
}

export const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const getTextUserRole = (roles) => {
    if(roles.indexOf("admin") > -1) return "Агент по недвижимости";
    if(roles.indexOf("user") > -1) return "Агент по недвижимости";
    return "Пользователь";
}

//обновляет параметры BookingDays связанные с текущей датой
export const updateDataParamsBookingDays = (bookingDays, bookingData, colors) => {

    // определяем не подтвержденные брони
    const notConfirmedIds = bookingData.filter(b => b.notConfirmed).map(b => b.bookingId);

    //определяем брони требующие удаление
    const forDeletionIds = bookingData.filter(b => b.forDeletion).map(b => b.bookingId);

    //корректировка booking
    //обновление/пересчет значений pastBooking, futureBooking, color - тк в зависимости от даты эти значения могут изменяться
    if(bookingDays && Object.keys(bookingDays).length){

        for (let dateId of Object.keys(bookingDays)) {

            if(bookingDays[dateId] && bookingDays[dateId].am && bookingDays[dateId].am.checkOut){

                const pastBookingAm = moment(bookingDays[dateId].am.checkOut).isBefore(moment().startOf('day'));
                bookingDays[dateId].am.pastBooking = pastBookingAm;
                bookingDays[dateId].am.color = (pastBookingAm?colors.pastBooking:colors.futureBooking);

                //если это не подтвержденное бронирование
                if( notConfirmedIds.length && notConfirmedIds.indexOf(bookingDays[dateId].am.id) > -1 ) bookingDays[dateId].am.color = colors.notConfirmedBooking;

                //если это бронирование помеченное на удаление
                if( forDeletionIds.length && forDeletionIds.indexOf(bookingDays[dateId].am.id) > -1 ) bookingDays[dateId].am.color = colors.forDeletionBooking;

            }

            if(bookingDays[dateId] && bookingDays[dateId].pm && bookingDays[dateId].pm.checkOut){

                const pastBookingPm = moment(bookingDays[dateId].pm.checkOut).isBefore(moment().startOf('day'));
                bookingDays[dateId].pm.pastBooking = pastBookingPm;
                bookingDays[dateId].pm.color = (pastBookingPm?colors.pastBooking:colors.futureBooking);

                //если это не подтвержденное бронирование
                if( notConfirmedIds.length && notConfirmedIds.indexOf(bookingDays[dateId].pm.id) > -1 ){
                    bookingDays[dateId].pm.color = colors.notConfirmedBooking;
                    bookingDays[dateId].pm.notConfirmed = true;
                }

                //если это бронирование помеченное на удаление
                if( forDeletionIds.length && forDeletionIds.indexOf(bookingDays[dateId].pm.id) > -1 ){
                    bookingDays[dateId].pm.color = colors.forDeletionBooking;
                    bookingDays[dateId].pm.forDeletion = true;
                }

            }

        }
    }

    return bookingDays;

}

export const getTextDistricts = (districts) => {

    if(districts.length == 4){
        //если выбрано больше 3 районов

        return "Выбрано "+districts.length+" района";

    }else if(districts.length > 4){
        //если выбрано больше 3 районов

        return "Выбрано "+districts.length+" районов";

    }else if(districts.length > 0 ){
        //если выбрано 1-3 района

        return districts.map(d => d.name).join(", ");

    }else{
        //если не выбраны районы (все районы)
        return "Все районы Сочи";
    }

};

export const getTextDays = (days, text) => {

    days = days.toString();

    switch (text) {
        case "дни":
            if(days[days.length-1] == "1" && days != "11") {
                return days+" день";
            }else if(["2", "3", "4"].indexOf(days[days.length-1]) > -1 && !(days >= 12 && days <= 14) ){
                return days+" дня";
            }else{
                return days+" дней"
            }
            break;
        case "сутки":
            if(days == "1") {
                return days+" сутки";
            }else if(["2", "3", "4"].indexOf(days[days.length-1]) > -1){
                return days+" дня"
            }else{
                return days+" суток"
            }
            break;
    }
}

export const getTextDate = (date, countDaysAgo) => {

    const dateEndDay = moment(date).endOf("day");
    const textDate = moment(date).format("DD.MM.YY");
    const days = moment().diff(dateEndDay, 'days');
    const today = moment().format("DD.MM.YY") == textDate;

    if(today) {
        return "Сегодня в "+moment(date).format("HH:mm");
    }else if(days == 0){
        return "Вчера в "+moment(date).format("HH:mm");
    }else if(days == 1){
        return "Позавчера в "+moment(date).format("HH:mm");
    }else if(days < 5){
        return days+" дня назад";
    }else if(days < (countDaysAgo+1)){
        return days+" дней назад";
    }else{
        return textDate;
    }

}

export const mapToObject = (from, iteratee = identity) => {
    const result = {};
    each(from, (value, key) => {
        const [k, v] = iteratee(value, key);
        if (v !== undefined)
            result[k] = v;
    });
    return result;
}

export const useToggle = (initial = false) => {
    const [state, setState] = useState(initial);
    return [state, val => setState(s => ((typeof val === 'boolean') ? val : !s))];
};

export const replObjInArr = (array, index, insert) => {
    //если объект не найден в массиве
    if(index < 0) return array;
    return [...array.slice(0, index), {...array[index], ...insert}, ...array.slice(index+1)];
}

export const getReHeader = (r, showLetters, showHousename) => {

    if(!r) return "?";

    const header = [];

    switch (r.rooms || null) {
        case "fa_reoptions_1":
            header.push("Студия");
            break;
        case "fa_reoptions_2":
            header.push("1-к");
            break;
        case "fa_reoptions_4":
            header.push("2-к");
            break;
        case "fa_reoptions_5":
            header.push("3-к");
            break;
        case "fa_reoptions_6":
            header.push("4-к");
            break;
        case "flda_reoptions_2":
            header.push("Дом");
            break;
        default:
            break
    }

    if(showHousename && r.houseName && r.houseName.title) header.push(" в "+r.houseName.title);

    if(r.area) header.push(r.area+"м²");

    if(r.floor) header.push(r.floor+(r.floorHouse?"/"+r.floorHouse:"")+"э");

    if(showLetters && r.lettersName) header.push("к."+r.lettersName);

    if(!(header && header.length)) return ((r.code && r.code+" ") || "")+"Объект";

    let result = ((r.code && "№"+r.code+", ") || "")+header.join(", ");

    return (result.length > 50?result.slice(0,50)+"…":result);

}

export const getReSquareHeader = (r) => {
    if(!r) return "?";
    const header = [];
    if(r.area) header.push(r.area+"м²");
    if(r.floor) header.push(r.floor+"э");

    if(header.length) return header.join(", ");

    //если не удалось найти сформировать header
    //пробуем получить код
    if(r.code) return("№"+r.code);

    //если не удалось сформировать
    switch (r.rooms || null) {
        case "fa_reoptions_1":
            return "Студия";
            break;
        case "fa_reoptions_2":
            return "1-к";
            break;
        case "fa_reoptions_4":
            return "2-к";
            break;
        case "fa_reoptions_5":
            return "3-к";
            break;
        case "fa_reoptions_6":
            return "4-к";
            break;
        case "flda_reoptions_2":
            return "Дом";
            break;
        default:
            return "Объект";
            break
    }
}


export const loadScript = (url) => {
    return new Promise((resolve, reject) => {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    })
}


export const priceToTriads = (price) => {
    const priceStr = price && price.toString();
    if(priceStr == "0") return "0";
    return priceStr && priceStr.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1.') || "?";
}

/*
export const getTextDays = (days, text) => {

    days = days.toString();

    switch (text) {
        case "дни":
            if(days[days.length-1] == "1" && days != "11") {
                return days+" день";
            }else if(["2", "3", "4"].indexOf(days[days.length-1]) > -1 && !(days >= 12 && days <= 14) ){
                return days+" дня";
            }else{
                return days+" дней"
            }
            break;
        case "сутки":
            if(days == "1") {
                return days+" сутки";
            }else if(["2", "3", "4"].indexOf(days[days.length-1]) > -1){
                return days+" дня"
            }else{
                return days+" суток"
            }
            break;
    }
}

 */

export const getPlatformIcon = (platform) => {
    switch (platform) {
        case "Avito":
            return <AvitoIcon style={{width:"1.5rem", height: "1.5rem"}}/>;
        case "Cian":
            return <CianIcon style={{width:"1.5rem", height: "1.5rem"}}/>;
        case "DomClick":
            return <DomClickIcon style={{width:"1.5rem", height: "1.5rem"}}/>;
        case "Catalog":
            return <AOIcon style={{width:"1.5rem", height: "1.5rem"}}/>;
        case "Other":
            return <OtherPlatformIcon style={{width:"1.5rem", height: "1.5rem"}}/>;
    }
}

export const getReTextStatus = (status) => {

    let textStatus;
    let color;
    let bgColor;
    let notActive = false;

    if(!status){
        color = "rgba(101, 119, 128, 1)";
        bgColor = "#f4f4f4";
        textStatus = "Без статуса";
        return {textStatus, color, bgColor};
    }

    switch (status) {
        case "published":
            color = "rgba(76, 175, 80, 1)";
            bgColor = "#f4fff6";
            textStatus = "Опубликовано";
            break;
        case "not_published":
            notActive = true;
            color = "rgba(101, 119, 128, 1)";
            bgColor = "#f4f4f4";
            textStatus = "Снято";
            break;
        case "check":
            color = "rgba(235, 109, 0, 1)";
            bgColor = "#fff3f3";
            textStatus = "На доработке";
            break;
        case "moderation":
            color = deepPurple[400];
            bgColor = "rgb(235 228 248)";
            textStatus = "Модерация";
            break;
        case "rejected":
            color = deepOrange[500];
            bgColor = "#fff3f3";
            textStatus = "Отклонено";
            break;
        case "blocked":
            color = "rgba(235, 51, 51, 1)";
            bgColor = "#fff3f3";
            textStatus = "Заблокировано";
            break;
        case "loading":
            color = blue[400];
            bgColor = "rgb(243 250 255)";
            textStatus = "Публикация";
            break;

        default:
            color = "rgba(101, 119, 128, 1)";
            bgColor = "#f4f4f4";
            textStatus = status;
            break;
    }

    return {textStatus, color, bgColor, notActive};

}
