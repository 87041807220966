import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import TextPageTpl from "../../components/TextPageTpl/TextPageTpl.jsx";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "./styles.js";
import DownloadIcon from '@mui/icons-material/Download';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Drawer from "@mui/material/Drawer/Drawer";
import ButtonGroup from "@mui/material/ButtonGroup";
import {Helmet} from "react-helmet-async";

const MechanizedPlastering = () => {

    const gClasses = useGStyles();
    const classes = useStyles();

    //глобальный state
    const {
        pubConfig,
        backdrop, setBackdrop,
        toast, setToast,
        lightbox, setLightbox,
    } = useContext(GlobalStateContext);


    //ActionSheet
    const [showActionSheet, setShowActionSheet] = useState(false);
    const [actionSheetType, setActionSheetType] = useState(null);

    const viewGalleryOurObjects = (id) => {

        const images = pubConfig.ourObjectsGallery.find(item => item.id == id).images.map(src => ({
            src,
        }));

        setLightbox({
            ...lightbox,
            show: true,
            images,
            imgIndex: 0,
            options: {
                showThumbnails: true,
            }
        });
    };

    const onDownloadPrice = (re) => {
        setActionSheetType("action_download_price");
        setShowActionSheet(true);
    }

    const downloadPrice = (url) => {
        setShowActionSheet(false);
        window.open(url, "_blank");
    }

    const renderActionSheetSwitch = () => {
        switch (actionSheetType) {
            case "action_download_price":
                return (
                    <ButtonGroup
                        variant="text"
                        orientation="vertical"
                    >
                        <Button
                            onClick={() => downloadPrice("/files/price_calculation_studio_20m2.pdf")}
                            size="large"
                        >
                            Студия 20м²
                        </Button>
                        <Button
                            onClick={() => downloadPrice("/files/price_calculation_46m2.pdf")}
                            size="large"
                        >
                            Евро 2-к квартира 46м²
                        </Button>
                    </ButtonGroup>
                );

            default:
                return null;
        }
    }

    const onCloseActionSheet = () => {
        setShowActionSheet(false);
    }

    const viewGalleryWeAreAtWork = (id) => {

        const imgIndex = pubConfig.weAreAtWorkGallery.map(i => i.id).indexOf(id);

        const images = pubConfig.weAreAtWorkGallery.map(i => ({
            src: i.src,
        }));

        setLightbox({
            ...lightbox,
            show: true,
            images,
            imgIndex,
            options: {
                showThumbnails: true,
            }
        });
    }

    const viewGalleryMechanizedPlasteringGallery = (id, category) => {

        const imgIndex = pubConfig.mechanizedPlasteringGallery.map(i => i.id).indexOf(id);

        const images = pubConfig.mechanizedPlasteringGallery.filter(i => i.category == category).map(i => ({
            src: i.src,
        }));

        setLightbox({
            ...lightbox,
            show: true,
            images,
            imgIndex,
            options: {
                showThumbnails: true,
            }
        });
    }


    return (
        <>
            <Helmet>
                <title>Высококачественная механизированная штукатурка в Сочи по самым выгодным ценам - от 600р/м2!</title>
                <meta name="description" content="Механизированная штукатурка стен в Сочи, в Адлере, Красной Поляне, Молдовке Веселом, Высоком, Хосте от 600р/м2. Высокое качество работ, низкие цены." />
                <meta name="keywords" content="Механизированная штукатурка стен в Сочи, в Адлере, Красной Поляне, Молдовке Веселом, Высоком, Хосте от 600р/м2. Высокое качество работ, низкие цены." />
            </Helmet>
            <TextPageTpl withMiniFooter={true} withoutPadding={true}>

                <BreadCrumbs stack={[{name: "Механизир. штукатурка"}]} style={{padding: "15px 15px 0 15px", marginBottom: 20}}/>

                <div className={clsx(gClasses.contentWrapper__text, classes.textPadding)}>
                    <h1>Механизированная штукатурка от мастеров команды <span style={{color: "#7546E6"}}>Бронирую!</span> </h1>

                    <div className={classes.mainBannerWrapper}>
                        <img src="/images/renovation_our_team.jpg" alt="" className={clsx(gClasses.textPageImg, gClasses.mb0)}/>
                        <div className={classes.mainBannerLogo}>
                            <img src="/svg/renovation-logo.svg" />
                        </div>
                    </div>

                    <h2>Почему мы лучшие в своем деле?</h2>

                    <p><b>Мы знаем о строительстве и ремонте всё, и даже больше!</b></p>
                    <p>Наши инженеры обладают высшим профильным образованием подкрепленным десятками лет трудового стажа в области строительно-монтажных и пуско-наладочных работ.</p>
                </div>

                <div className={clsx(classes.ourObjects)}>

                    <div className={clsx(gClasses.contentWrapper__text, classes.textPadding)}>
                        <h2>Реализованные проекты</h2>
                        <h3><span className={gClasses.tagStyle} style={{background: "#62BC00"}}>Квартиры и апартаменты</span></h3>
                        <p>Примеры реализованных проектов по механизированной штукатурке стен в квартирах и апартаментах Сочи, Адлере и Красной Поляне.</p>
                    </div>

                    <div className={classes.weAreAtWork}>
                        <div className={classes.weAreAtWorkWrapper}>
                            {
                                pubConfig.mechanizedPlasteringGallery.filter(item => item.category == "apartaments").map(item => (
                                    <div key={item.id} className={classes.weAreAtWorkItem} onClick={() => viewGalleryMechanizedPlasteringGallery(item.id, "apartaments")}>
                                        <img src={item.src}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className={clsx(gClasses.contentWrapper__text, classes.textPadding)}>
                        <h3 style={{marginTop: 35}}><span className={gClasses.tagStyle} style={{background: "#62BC00"}}>Дома и таунхаусы</span></h3>
                        <p>Фотографии работ по штукатурке механизированным способом в жилых домах и таунхаусах в различных районах Сочи: Молдовка, Хоста, Веселое, Высокое.</p>
                    </div>

                    <div className={classes.weAreAtWork}>
                        <div className={classes.weAreAtWorkWrapper}>
                            {
                                pubConfig.mechanizedPlasteringGallery.filter(item => item.category == "houses").map(item => (
                                    <div key={item.id} className={classes.weAreAtWorkItem} onClick={() => viewGalleryMechanizedPlasteringGallery(item.id, "houses")}>
                                        <img src={item.src}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>

                <div className={clsx(gClasses.contentWrapper__text, classes.textPadding)}>

                    <h2 className={gClasses.mb10}>Безкомпромисное качество работ</h2>
                    <p>Мы обладаем многолетним опытом работы в области отделочных и штукатурных работ, все работы выполняются квалифицированными мастерами, согласно СНиП 3.04.01-87 «Изоляционные и отделочные покрытия», или по немецкому стандарту DIN V 18550 «Штукатурка и штукатурные системы», обеспечивая высокое качество поверхности в пределах Q3-Q4.</p>
                    <p>В нашей работе мы используем только профессиональное оборудование ведущих мировых производителей PFT, Bosh, Hilti, ADA, Makita, Зубр, работы по механизированной штукатурке выполняются на немецких штукатурных станциях PFT «Ritmo XL», PFT «Ritmo L», с соблюдением всех норм технологических процессов.</p>
                </div>

                <div className={classes.equipment}>
                    <div className={classes.equipmentWrapper}>

                        <div className={classes.equipmentItem}>
                            <img src="/images/equipment/pft.jpg" alt=""/>
                        </div>

                        <div className={classes.equipmentItem}>
                            <img src="/images/equipment/bosch.jpg" alt=""/>
                        </div>

                        <div className={classes.equipmentItem}>
                            <img src="/images/equipment/makita.jpg" alt=""/>
                        </div>

                        <div className={classes.equipmentItem}>
                            <img src="/images/equipment/ada.jpg" alt=""/>
                        </div>

                        <div className={classes.equipmentItem}>
                            <img src="/images/equipment/hilti.jpg" alt=""/>
                        </div>

                        <div className={classes.equipmentItem}>
                            <img src="/images/equipment/zubr.jpg" alt=""/>
                        </div>

                    </div>
                </div>

                <div className={clsx(gClasses.contentWrapper__text, classes.textPadding)} style={{marginBottom: 35}}>
                    <h2 className={gClasses.mb10}>Наш рабочий процесс</h2>
                    <p>Работы по механизированной штукатурке ведутся в несколько этапов:</p>
                    <ul>
                        <li>Выезд на замеры объекта - определяется общая площадь стен, которые необходимо заштукатурить и кол-во погонных метров плоскостей, ширина которых менее 500мм (откосы, колонны, ригели);</li>
                        <li>Подписание договора на выполнение работ - фиксируется расценка штукатурки одного м², а также сроки и условия выполнения работ;</li>
                        <li>Завоз материала и оборудования на объект;</li>
                        <li>Подготовительные работы - грунтовка стен, покраска металлических элементов, установка маячковых и угловых профилей, оконных профилей примыкания.</li>
                        <li>Выполнение непосредственно штукатурных работ - работы выполняются преимущественно гипсовыми смесями такими как  «MP 75», «MN START» от Knauf или «ГИПС-АКТИВ», «МАКСИМУМ» от ВОЛМА. В мокрых зонах цементно-песчаная смесь, такай как "Грюнбанд" от Knauf, «Жане» от Habez;</li>
                        <li>Сдача выполненных работ заказчику - выполняется подготовка объекта к сдаче (уборка, складирование мусора), сдача объекта заказчику с проверкой плоскостей, углов и откосов;</li>
                        <li>Вывоз оборудования и расчет заказчика за выполненные работы.</li>
                    </ul>
                    <p>Соблюдение нами всех норм технологического процесса, высокая квалификация и опыт наших мастеров гарантирует вам высокое качество результата выполненных штукатурных работ.</p>
                </div>

                {/*
                <div className={classes.weAreAtWork}>
                    <div className={classes.weAreAtWorkWrapper}>
                        {
                            pubConfig.weAreAtWorkGallery.map(item => (
                                <div key={item.id} className={classes.weAreAtWorkItem} onClick={() => viewGalleryWeAreAtWork(item.id)}>
                                    <img src={item.src}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
                */}

                <div className={clsx(classes.prices)}>
                    <h2>Стоимость работ</h2>

                    <div className={classes.priceInfoBanner}>
                        <p className={classes.priceInfoBanner__header}>до 650 ₽/м²</p>
                        <p className={classes.priceInfoBanner__text}><b>Стоимость работ по механизированной штукатурке</b> включает в себя подготовительные работы, работы по штукатурке и уборку объекта после выполнения работ.</p>
                    </div>
                </div>

                <div className={clsx(classes.contacts)}>
                    <h2>Свяжитесь с нами</h2>

                    <div className={classes.contactsWrapper}>

                        <div className={classes.contacts__item}>
                            <img src="/images/top_manager.jpg"/>
                            <p className={classes.contacts__name}>Георгий</p>
                            <p className={classes.contacts__job_title}>Ведущий менеджер</p>
                            <a href="tel:+79225009990" className={classes.contacts__phone}>+7 (922) 500-99-90</a>
                            <Button
                                startIcon={<WhatsAppIcon/>}
                                className={gClasses.mediumBtn}
                                variant="contained"
                                style={{background: "#4CAF50"}}
                                size="large"
                                onClick={() => window.open("https://wa.me/79225009990", "_blank")}
                            >
                                WhatsApp
                            </Button>
                        </div>

                        <div className={classes.contacts__item}>
                            <img src="/images/chief_engineer.jpg"/>
                            <p className={classes.contacts__name}>Андрей</p>
                            <p className={classes.contacts__job_title}>Главный инженер</p>
                            <a href="tel:+79223227472" className={classes.contacts__phone}>+7 (922) 322-74-72</a>
                            <Button
                                startIcon={<WhatsAppIcon/>}
                                className={gClasses.mediumBtn}
                                variant="contained"
                                style={{background: "#4CAF50"}}
                                size="large"
                                onClick={() => window.open("https://wa.me/79223227472", "_blank")}
                            >
                                WhatsApp
                            </Button>
                        </div>

                    </div>

                </div>

                <Drawer
                    anchor="bottom"
                    open={showActionSheet}
                    onClose={() => onCloseActionSheet()}
                    className={gClasses.actionSheet}
                >
                    <div className={gClasses.actionSheetWrapper}>
                        <div className={gClasses.drawerFrame}>
                            {renderActionSheetSwitch()}
                            <Button className={gClasses.actionSheetWrapperCancelBtn} variant="text" size="large" onClick={() => setShowActionSheet(false)}>Отмена</Button>
                        </div>
                    </div>

                </Drawer>


            </TextPageTpl>
        </>
    );
};

export default MechanizedPlastering;
